var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-popover',{attrs:{"target":`${_vm.label_triggers}${_vm.object.id}`,"placement":_vm.placement,"triggers":_vm.triggers},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.title))])]},proxy:true}])},[(_vm.content.length)?_vm._l((_vm.content),function(item){return _c('b-badge',{key:item.id,staticClass:"preq-badge",style:({
          'background-color': _vm.formationLine(item.formation_line)
            ? _vm.formationLine(item.formation_line).color
            : _vm.default_bg_color,
          color:
            (_vm.formationLine(item.formation_line) &&
              _vm.$lightOrDark(_vm.formationLine(item.formation_line).color)) ||
            !_vm.formationLine(item.formation_line)
              ? _vm.default_color
              : _vm.default_bg_color,
        })},[_c('span',{staticClass:"preq-order",style:({
            'background-color': _vm.formationLine(item.formation_line)
              ? _vm.default_order_bg_color
              : _vm.default_color,
            color: _vm.formationLine(item.formation_line)
              ? _vm.default_color
              : _vm.default_order_bg_color,
          })},[_vm._v(_vm._s(item.order))]),_vm._v(" "+_vm._s(item.code)+" - "+_vm._s(item.name)+" ")])}):[_c('div',[_vm._v(" Esta "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", false, "Asignatura"))+" no cuenta con prerrequisitos. ")])]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
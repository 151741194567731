<template>
  <div>
    <b-popover
      :target="`${label_triggers}${object.id}`"
      :placement="placement"
      :triggers="triggers"
    >
      <template #title>
        <div>{{ title }}</div>
      </template>
      <template v-if="content.length">
        <b-badge
          class="preq-badge"
          v-for="item in content"
          :key="item.id"
          :style="{
            'background-color': formationLine(item.formation_line)
              ? formationLine(item.formation_line).color
              : default_bg_color,
            color:
              (formationLine(item.formation_line) &&
                $lightOrDark(formationLine(item.formation_line).color)) ||
              !formationLine(item.formation_line)
                ? default_color
                : default_bg_color,
          }"
        >
          <span
            class="preq-order"
            :style="{
              'background-color': formationLine(item.formation_line)
                ? default_order_bg_color
                : default_color,
              color: formationLine(item.formation_line)
                ? default_color
                : default_order_bg_color,
            }"
            >{{ item.order }}</span
          >
          {{ item.code }} - {{ item.name }}
        </b-badge>
      </template>
      <template v-else>
        <div>
          Esta
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
          no cuenta con prerrequisitos.
        </div>
      </template>
    </b-popover>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "PrerequisitesPopover",
  props: {
    object: {
      type: Object,
    },
    content: {
      type: Array,
    },
    placement: {
      type: String,
      default: "bottom",
    },
    triggers: {
      type: String,
      default: "hover",
    },
    label_triggers: {
      type: String,
      default: "prerequisite-label-",
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      default_order_bg_color: "#ffffff",
      default_color: "var(--primary-font-color)",
      default_order_color: "#6c757d",
      default_bg_color: "#f8f9fa",
    };
    // return {
    //   default_bg_color: "#ffffff", default_color:"#6c757d",
    //   styleObject: {
    //     color: "#6c757d",
    //     backgroundColor: "#ffffff",
    //   },
    // };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      formation_lines: names.FORMATION_LINES,
    }),
  },
  methods: {
    formationLine(formation_line_id) {
      return this.formation_lines.find((x) => x.id == formation_line_id);
    },
  },
  created() {},
};
</script>
<style scoped>
.preq-badge {
  padding: 0.3rem !important;
  margin: 0.1rem;
}
.preq-order {
  background: #fff;
  color: #6c757d;
  max-width: 40px !important;
  padding-inline: 0.2rem;
  border-radius: 4px;
  margin-right: 0.1rem;
}
.colorless {
  background: #6c757d !important;
  color: #fff !important;
}
.popover {
  max-width: 385px;
}
</style>
